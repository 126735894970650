<template>
  <div ref="gismap">
    <a-modal
      v-model="visible"
      title=""
      :footer="null"
      :closable="false"
      :maskClosable="false"
      :width="420"
      :getContainer="() => $refs.gismap"
    >
      <div style="padding: 16px">
        <p class="tips_title">
          <a-icon
            type="exclamation-circle"
            style="margin-right: 16px; font-size: 22px; color: red"
          />百夫长授权功能维护中，
        </p>
        <p class="tips_title" style="margin-left: 38px">
          点击跳转通用授权页面授权。
        </p>
        <div class="model_style">
          <a-button type="primary" @click="goAccredit" size="large"
            >去授权</a-button
          >
        </div>
      </div>
    </a-modal>
    <a-tabs :active-key="activeTab" @change="onTabChange">
      <a-tab-pane key="OpenAuthorization" tab="互动数据授权">
        <open-authorization-tab
          ref="OpenAuthorization"
          :author="author"
        ></open-authorization-tab>
      </a-tab-pane>
      <a-tab-pane key="ServiceAuthorization" tab="直播数据授权">
        <service-authorization-tab
          ref="ServiceAuthorization"
          :author="author"
        ></service-authorization-tab>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<style scoped>
</style>
<script>
import OpenAuthorizationTab from "./OpenAuthorizationTab";
import ServiceAuthorizationTab from "./ServiceAuthorizationTab";

export default {
  components: { OpenAuthorizationTab, ServiceAuthorizationTab },
  props: {
    authorId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      author: {
        authorId: this.authorId,
      },
      loading: false,
      activeTab: "OpenAuthorization",
      visible: true,
    };
  },
  methods: {
    init() {
      this.$route.query.activeTab &&
        (this.activeTab = this.$route.query.activeTab);
      this.load();
      this.onTabChange(this.activeTab);
    },
    onTabChange(activeTab) {
      this.activeTab = activeTab;
      this.$nextTick(() => this.$refs[this.activeTab].$emit("init"));
    },
    load() {
      console.log(this.author.authorId);
      this.loading = true;
      this.$api.core.AuthorService.get(this.authorId)
        .then((res) => {
          this.author = res || { authorId: this.authorId };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goAccredit() {
      let url = "";
      console.log("process.env.VUE_APP_ENV", process.env.VUE_APP_ENV);
      switch (process.env.VUE_APP_ENV) {
        case "dev":
          url = "https://auth.afanticar.net/douyin/dyopen";
          break;
        case "uat":
          url = "https://auth.afanticar.cn/douyin/dyopen";
          break;
        case "pro":
          url = "https://auth.afanticar.com/douyin/dyopen";
          break;
      }
      window.open(url);
    },
  },
  created() {},
  mounted() {
    this.$on("init", this.init);
  },
};
</script>
<style lang="less" scoped>
.model_style {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.tips_title {
  font-size: 18px;
  line-height: 1.2;
  color: rgba(0, 0, 0, 0.85);
  font-size: 500;
}
.gismap {
  background: red;
}
</style>
