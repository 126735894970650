<template>
  <div>
    <a-row type="flex" style="flex-direction: column; align-content: center; align-items: center; text-align: center">
      <a-spin :spinning="spinning">
        <a-col>
          <div class="f24" style="margin: 32px 0 8px">{{ author.nickname }}</div>
          <div class="f14">抖音 {{ author.code }}</div>
        </a-col>
        <a-col>
          <template>
            <a-tag v-if="status == 2" color="red">
              <a-icon type="close-circle" theme="filled"/>
              该播主授权失效,请重新扫码授权
            </a-tag>
            <a-tag v-if="status == 1" color="green">
              <a-icon type="check-circle" theme="filled"/>
              该播主已授权
            </a-tag>
          </template>
        </a-col>
      </a-spin>
      <a-col>
        <img class="tip" src="@/assets/tip.png" alt=""/>
      </a-col>
      <a-col>
        <div class="sure-btn" @click="openAuth">立即授权
          <a-modal v-model="visible" title="直播数据授权" @cancel="cancel" footer :bodyStyle="{minHeight:'300px',textAlign:'center'}">
              <a-row>
                <a-col style="margin-bottom: 10px">
                  <span>使用抖音短视频APP扫码授权,提升数据准确性</span>
                </a-col>
                <a-col v-if="![0,1].includes(status)" style="margin-bottom: 20px">
                  <a-tag v-if="status == 2" color="red">
                    <a-icon type="close-circle" theme="filled"/>
                    该播主授权失效,请重新扫码授权
                  </a-tag>
                  <a-tag v-if="status == 3" color="red">
                    <a-icon type="close-circle" theme="filled"/>
                    本次授权失败,帐号不一致,请使用该播主帐号进行重新扫码授权
                  </a-tag>
                </a-col>
                <template v-if="status == 0">
                  <a-col style="height: 200px;">
                    <a-spin :spinning="spinning">
                      <div class="qr-container">
                        <img v-show="data.qrcode_url" class="qr-code" :src="data.qrcode_url" alt/>
                        <img class="dy-logo" src="@/assets/images/douyin.png" height="76" width="76"/></div>
                    </a-spin>
                  </a-col>
                  <a-col>
                    <span>{{ getLabel()  }} <a type="link" @click="refresh">刷新二维码</a></span>
                  </a-col>
                </template>
                <template v-else>
                  <a-col style="height: 200px;">
                    <a-icon v-if="status == 1" type="check-circle" theme="filled" style="font-size: 150px;color: #52c41a;margin: 25px"/>
                    <div v-else class="qr-expired">
                      <img class="qr" src="@/assets/images/invalid.png" alt/>
                      <div class="btn-refresh" @click="refresh">
                        <img class="refresh-hover" src="@/assets/images/refresh.png" alt="">
                      </div>
                      <div class="hint-refresh">二维码失效，点击刷新</div>
                    </div>
                  </a-col>
                  <a-col>
                    <span style="color: red">{{ getLabel() }} <a v-if="status == 1" type="link" @click="refresh"></a></span>
                  </a-col>
                </template>
              </a-row>
          </a-modal>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<style lang="scss" scoped>
.f24 {
  font-size: 24px;
  font-weight: bold;
}

.f14 {
  color: #8f94a2;
}

img.tip {
  margin: 32px 0 64px;
}

.sure-btn {
  width: 292px;
  height: 42px;
  line-height: 42px;
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  color: #4a58f3;
  font-weight: bold;
  border: 1px solid #4a58f3;
  border-radius: 7.2px;
}

.sp{
  margin: 0px;
}

div.qr-container, img.qr-code {
  position: relative;
  //width: 200px;
  height: 200px;
}
div.qr-container img.dy-logo {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background: white;
  border: 2px solid white;
}
.qr-expired {
  position: relative;
  margin: auto;
  width: 180px;
  user-select: none;
}
.qr{
  width: 180px;
  height: 180px;
}
.btn-refresh {
  cursor: pointer;
  position: absolute;
  width: 180px;
  //height: 70px;
  top: 36px;
  //left: 55px;
}
.btn-refresh .refresh-hover{
  width: 70px;
  height: 70px;
  opacity: 0;
}
.btn-refresh .refresh-hover:hover {
  opacity: 1;
}

.hint-refresh{
  position: absolute;
  top: 120px;
  left: 0;
  width: 180px;
  text-align: center;
  font-size: 12px;
}

</style>
<script>


let cancel

export default {
  computed:{

  },
  props:{
    author: {
      type: Object,
      required: true
    },
  },
  data(){
    return {
      visible: false,
      data: {},
      spinning: false,
      status: 0,
    }
  },
  components:{},
  methods:{
    getLabel() {
      return ['60s后二维码失效', '本次授权操作成功'][this.status]
    },
    init(){
      this.search()
    },
    search() {
      this.spinning = true
      this.$api.core.Auth.getStatus(this.author.authorId).then(res => {
        this.status = res
      }).finally(()=>{
        this.spinning = false
      })
    },
    refresh(){
      this.load()
    },
    openAuth(){
      this.visible = true
      this.load()
    },
    load(){
      this.spinning = true
      this.data = {}
      this.status = 0
      this.$api.core.Auth.getQRCode(this.author.authorId).then(res =>{
        this.data = res || {}
        this.getScanCodeStatus()
      }).finally(()=>{
        this.spinning = false
      })
    },
    getScanCodeStatus(){
      this.cancel()
      this.$api.core.Auth.getScanCodeStatus(this.author.authorId,this.data.token, c => cancel = c).then(res =>{
          this.status = res
        console.log(res)
      }).catch(e => {
        const {error = {},disableDefaultHandler} = e
        if (error.httpCode == '600') {
          disableDefaultHandler && disableDefaultHandler()
        } else {
          this.status = 2
        }
      })
    },
    cancel() {
      cancel && cancel() && (cancel = undefined)
    }
  },
  created() {
    this.$on('init',this.init)
  },
  mounted() {
  }
}

</script>