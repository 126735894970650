<template>
      <a-row type="flex" style="flex-direction: column; align-content: center; align-items: center; text-align: center">
        <a-spin :spinning="loading">
          <a-col>
            <div class="f24" style="margin: 32px 0 8px">{{ author.nickname }}</div>
            <div class="f14">抖音 {{ author.code }}</div>
          </a-col>
          <a-col>
            <template v-if="$route.params.auth == undefined">
              <a-tag v-if="oauthStatus == 0" color="red">
                <a-icon type="close-circle" theme="filled"/>
                未授权
              </a-tag>
              <a-tag v-if="oauthStatus == 1" color="green">
                <a-icon type="check-circle" theme="filled"/>
                该播主已授权,重新扫码授权,可延长授权有效时间
              </a-tag>
              <a-tag v-if="oauthStatus == 2 || oauthStatus == 3" color="red">
                <a-icon type="close-circle" theme="filled"/>
                该播主授权失效,请重新扫码授权
              </a-tag>
              <a-tag v-if="oauthStatus == 4" color="red">
                <a-icon type="close-circle" theme="filled"/>
                正在检查用户一致性
              </a-tag>
              <a-tag v-if="oauthStatus == 5" color="red">
                <a-icon type="close-circle" theme="filled"/>
                用户一致性检查报错
              </a-tag>
              <a-tag v-if="oauthStatus == 6" color="red">
                <a-icon type="close-circle" theme="filled"/>
                需要授权的帐号与扫码帐号不一致
              </a-tag>
            </template>
          </a-col>
        </a-spin>
        <a-col>
          <a-tag v-if="$route.params.auth == 'false'" color="red">
            <a-icon type="close-circle" theme="filled"/>
            授权失败
          </a-tag>
          <a-tag v-else-if="$route.params.auth == 'true'" color="green">
            <a-icon type="check-circle" theme="filled"/>
            授权成功
          </a-tag>
        </a-col>
        <a-col>
          <img src="@/assets/tip.png" alt=""/>
        </a-col>
        <a-col v-if="oauthStatus != 4">
          <div class="sure-btn" @click="openAuth">立即授权</div>
        </a-col>
      </a-row>
</template>

<script>
// oauthStatus
// 0.未授权；

// 1.已授权；

// 2.授权失效；3授权过期；->授权失效

// 4正在检查用户一致性；->授权审核中

// 5用户一致性检查报错；6.需要授权的帐号与扫码帐号不一致 ->授权失效
export default {
  props: {
    author: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      url: '',
      oauthStatus: 0,
      loading: false,
    };
  },
  methods: {
    handleAuth() {
      const a = document.createElement('a')
      a.href = process.env.VUE_APP_AUTH_PATH
      const apiHost = a.href.substr(0, a.href.length - 1)
      a.href = this.$router.resolve({ name: 'authorAuth', params: { authorId: this.author.authorId }, query: { auth: 'true' }}).href
      const redirectUri = a.href
      this.$api.core.Auth.getAuthUrl({ apiHost, redirectUri, authorId: this.author.authorId }).then(res => {
        this.url = res.data
      })
    },
    openAuth() {
      // window.open(this.url)
      location.href = this.url
    },
    search() {
      this.loading = true
      this.$api.core.Auth.getAuthStatus(this.author.authorId).then(res => {
        this.oauthStatus = res[this.author.authorId]
      }).finally(()=>{
        this.loading = false
      })
    },
    init() {
      this.handleAuth()
      this.search()
    }
  },
  created() {
    this.$on("init", this.init);
  },
  mounted() {
    this.$route.query.auth && this.$router.push({
      name: this.$route.name,
      params: {authorId: this.author.authorId, auth: this.$route.query.auth}
    })
  },
};
</script>

<style lang="scss" scoped>
.f24 {
  font-size: 24px;
  font-weight: bold;
}

.f14 {
  color: #8f94a2;
}

img {
  margin: 32px 0 64px;
}

.sure-btn {
  width: 292px;
  height: 42px;
  line-height: 42px;
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  color: #4a58f3;
  font-weight: bold;
  border: 1px solid #4a58f3;
  //   box-shadow: 0 2px 6px 0;
  border-radius: 7.2px;
}
</style>

